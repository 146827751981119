import request from '../request'

//装箱清单列表
export const car_part_index_request = p => {
    return request({
        method:'GET',
        url:'car_part/index',
        params: p
    })
}

//装箱清单详情
export const car_part_details_request = id => {
    return request({
        method:'GET',
        url:'car_part/details',
        params: {
            id
        }
    })
}

//装箱清单导入
export const car_part_excel_add_request = data => {
    return request({
        method:'POST',
        url:'car_part/excel_add',
        data
    })
}

//新增装箱清单
export const car_part_add_request = data => {
    return request({
        method:'POST',
        url:'car_part/add',
        data
    })
}

//修改装箱清单
export const car_part_edit_request = data => {
    return request({
        method:'PUT',
        url:'car_part/edit',
        data
    })
}

//删除装箱清单
export const car_part_del_request = id => {
    return request({
        method:'DELETE',
        url:'car_part/del',
        data: {
            id: id
        }
    })
}