<template>
    <div v-loading.fullscreen.lock="loading">
        <car-part-search-bar-component
            add_auth="新增装箱清单"
            @search="get_car_part_index"
            @addTeam="addTeam"
        ></car-part-search-bar-component>
        <common-table-component
            details_auth="装箱清单详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_car_part"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_car_part_index"
        ></common-page-component>
        <car-part-edit-component
            edit_auth="修改装箱清单"
            del_auth="删除装箱清单"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :car_part_details_data="fcar_part_details_data"
            @exitDialog="dialogExit"
            @search="get_car_part_index"
            @show_edit="show_edit"
            @details_row="details_car_part"
        ></car-part-edit-component>
    </div>
</template>

<script>

import { car_part_index_request,car_part_details_request } from '@/network/WareHouse/CarPart.js'

import CarPartSearchBarComponent from '@/components/WareHouse/CarPart/CarPartSearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import CarPartEditComponent from '@/components/WareHouse/CarPart/CarPartEditComponent'

export default {
    name:'CarPartView',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                batch_number: '',
                specifications: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'state',
                    label: '状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '未核对'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '已核对'
                            }
                        }
                    }
                },
                {
                    prop: 'name',
                    label: '清单名称',
                    minWidth: '200px'
                },
                {
                    prop: 'batch_number',
                    label: '零件号',
                    minWidth: '120px'
                },
                {
                    prop: 'specifications',
                    label: '托盘号',
                    minWidth: '120px'
                },
                {
                    prop: 'create_staff_name',
                    label: '创建人',
                    minWidth: '100px'
                },
                {
                    prop: 'create_time',
                    label: '创建时间',
                    minWidth: '180px'
                }
            ],
            fcar_part_details_data: {}
        }
    },
    computed:{},
    methods:{
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_car_part_index(param = {}) {
            this.loading = true
            this.cond.state = param.state ?? this.cond.state
            this.cond.name = param.name ?? this.cond.name
            this.cond.batch_number = param.batch_number ?? this.cond.batch_number
            this.cond.specifications = param.specifications ?? this.cond.specifications
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            car_part_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_car_part(id) {
            this.fid = id
            this.fdialogFormVisible = true
            car_part_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fcar_part_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        }
    },
    filters:{},
    props:{},
    created(){
        this.get_car_part_index()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CarPartSearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CarPartEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>