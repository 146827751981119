<template>
    <el-card class="box-card">
        <!-- 表单只有一个文本框的时候，按回车会自动submit表单，需要禁用sunmit -->
        <el-form
            ref="searchform"
            :inline="true"
            :model="form"
            @submit.native.prevent
            size="medium"
        >
            <el-form-item label="核对状态" prop="state">
                <el-select
                    v-model="form.state"
                    placeholder="选择状态"
                    class="statec"
                    filterable
                    clearable
                >
                    <el-option
                        v-for="(value,index) in ['未核对','已核对']"
                        :key="value"
                        :label="value"
                        :value="index"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="清单名称" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="清单名称搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="零件号" prop="batch_number">
                <el-input
                    v-model="form.batch_number"
                    placeholder="零件号搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item label="托盘号" prop="specifications">
                <el-input
                    v-model="form.specifications"
                    placeholder="托盘号搜索"
                    @keyup.enter.native="search()"
                    clearable
                ></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" plain @click="search">搜索</el-button>
                <el-button type="warning" plain @click="resetbtn('searchform')"
                    >重置</el-button
                >
                <el-button
                    v-if="$_has('新增装箱清单')"
                    type="success"
                    plain
                    round
                    @click="addTeam"
                    class="temp_add"
                    size="medium"
                    >添加装箱清单</el-button
                >
                <common-excel-component
                    v-if="$_has('导入装箱清单')"
                    name='导入装箱清单'
                    :header="excel_header"
                    @excel_arr="excel_arr"
                ></common-excel-component>
            </el-form-item>
        </el-form>
    </el-card>
</template>

<script>
import { car_part_excel_add_request} from '@/network/WareHouse/CarPart.js'
import CommonExcelComponent from '@/components/common/CommonExcelComponent'

export default {
    name: 'CarPartSearchBarComponent',
    data() {
        return {
            form: {
                state: '',
                name: '',
                batch_number: '',
                specifications: '',
                page: 1,
            },
            excel_header: [
                {
                    code: 'name',
                    name: '清单名称'
                },{
                    code: 'batch_number',
                    name: '零件号'
                },{
                    code: 'specifications',
                    name: '托盘号'
                }
            ]
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.$emit('addTeam')
        },
        search() {
            this.$emit('search', this.form)
        },
        resetbtn(f) {
            this.$refs[f].resetFields()
        },
        excel_arr(arr) {
            car_part_excel_add_request({car_part: arr})
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('导入成功!')
                        this.$emit('search')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        }
    },
    filters: {},
    props: {},
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        CommonExcelComponent
    },
    watch: {},
}
</script>

<style>
.temp_add {
    float: right;
    margin-bottom: 10px;
}
</style>